import { TableCard } from "@3t/utils";
import { Box } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import CustomersModal from "./components/CustomersModal";

const PreCustomers = () => {
  return (
    <MainLayout>
      <SWRrender>
        <Customers />
      </SWRrender>
    </MainLayout>
  );
};

const Customers = () => {
  const {
    data: { clients },
  } = useSWR("/clients", { suspense: true });
  const columns = [
    {
      Header: "Nº Cliente",
      accessor: "id",
    },
    {
      Header: "Nombre",
      accessor: "name",
    },
  ];
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Box>
        <TableCard
          addButton={<IconPlus />}
          onAddButtonClick={() => setShowModal(true)}
          onRowClick={(row) => navigate(`/customers/${row.id}`)}
          searchPlaceholder="Buscar cliente"
          columns={columns}
          data={clients}
          title="Listado de clientes"
        />
      </Box>
      <CustomersModal opened={showModal} setOpened={setShowModal} />
    </>
  );
};

export default PreCustomers;
