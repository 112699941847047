import { Card, Skeleton } from "@mantine/core";

interface LoadingProps {
  height?: number;
}
const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { height = 250 } = props;
  return (
    <>
      <Skeleton mt="sm">
        <div style={{ width: "100%", height: height + "px" }}></div>
      </Skeleton>
    </>
  );
};

export default Loading;
