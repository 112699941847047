import { useEffect, useState } from "react";
import { Modal, Button, TextInput, Select, Grid, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import api from "../../../../api";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";
import useSWR, { mutate } from "swr";
import SWRrender from "../../../../components/SWRrender";
import { openConfirmModal } from "@mantine/modals";

interface TransactionsModalProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  transaction?: any;
  setTransaction: (value: any) => void;
}

const TransactionsModal = (props: TransactionsModalProps) => {
  const { opened, setOpened, transaction, setTransaction } = props;

  const form = useForm({
    initialValues: {
      invoice_id: "",
      invoice_date: new Date(),
      client_id: "",
      ammount: "",
      paid_at: "",
    },
  });

  useEffect(() => {
    if (opened && transaction) {
      const data = { ...transaction };
      data.invoice_date = dayjs(data.invoce_date).toDate();
      data.client_id = String(data.client_id);
      data.paid_at = data.paid_at ? "true" : "false";

      form.setValues(data);
    }

    if (!opened) {
      form.reset();
      setTransaction(null);
    }
  }, [opened]);

  const create = () => {
    api
      .post("/orders", {
        ...form.values,
        invoice_date: form.values.invoice_date.toDateString(),
        paid_at: form.values.paid_at === "true" ? new Date() : null,
      })
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Operación creada correctamente",
          });
          mutate("/orders");
          setOpened(false);
        } else {
          showErrorToast({
            message: "Error al crear la operación",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al crear la operación",
        });
      });
  };

  const update = () => {
    api
      .put(`/orders/${transaction.id}`, {
        ...form.values,
        paid_at: form.values.paid_at === "true" ? new Date() : null,
        invoice_date: form.values.invoice_date.toDateString(),
      })
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Operación actualizada correctamente",
          });
          mutate("/orders");
          setOpened(false);
        } else {
          showErrorToast({
            message: "Error al actualizar la operación",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al actualizar la operación",
        });
      });
  };

  const openDeleteModal = () => {
    openConfirmModal({
      title: "Eliminar operación",
      children: (
        <Text size="sm">
          ¿Estás seguro de que quieres eliminar la operación ? Esta acción no se
          puede deshacer.
        </Text>
      ),
      labels: { confirm: "Confirmar", cancel: "Cancelar" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        api
          .delete(`/orders/${transaction.id}`)
          .then((res) => {
            if (res.data.ok) {
              showSuccessToast({
                message: "Operación eliminada correctamente",
              });
              mutate("/orders");
              setOpened(false);
            } else {
              showErrorToast({
                message: "Error al eliminar la operación",
              });
            }
          })
          .catch((err) => {
            showErrorToast({
              message: "Error al eliminar la operación",
            });
          });
      },
    });
    setOpened(false);
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={transaction ? "Editar operación" : "Crear operación"}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            if (transaction) {
              update();
            } else {
              create();
            }
          })}
        >
          <TextInput
            name="invoice_id"
            label="Número de factura"
            {...form.getInputProps("invoice_id")}
            required
          />

          <DatePicker
            name="invoice_date"
            label="Fecha de factura"
            {...form.getInputProps("invoice_date")}
            required
            inputFormat="DD/MM/YYYY"
            mt="sm"
          />

          <SWRrender>
            <RenderCustomerSelect form={form} />
          </SWRrender>

          <TextInput
            name="precio"
            label="Precio"
            {...form.getInputProps("ammount")}
            required
            mt="sm"
          />

          <Select
            name="paid"
            label="Pagado"
            {...form.getInputProps("paid_at")}
            required
            mt="sm"
            data={[
              { label: "Sí", value: "true" },
              { label: "No", value: "false" },
            ]}
          />

          <Grid>
            {transaction && (
              <Grid.Col span={6}>
                <Button
                  onClick={openDeleteModal}
                  fullWidth
                  mt="sm"
                  color={"red"}
                >
                  Eliminar
                </Button>
              </Grid.Col>
            )}
            <Grid.Col span={transaction ? 6 : 12}>
              <Button fullWidth mt={"sm"} type="submit">
                {transaction ? "Editar" : "Crear"} operación
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
    </>
  );
};

const RenderCustomerSelect = (props: any) => {
  const { form } = props;

  const {
    data: { clients },
  } = useSWR("/clients", { suspense: true });

  const selectData = clients.map((client: any) => {
    return {
      label: client.name,
      value: String(client.id),
    };
  });
  return (
    <Select
      name="customer"
      label="Cliente"
      {...form.getInputProps("client_id")}
      required
      data={selectData}
      mt="sm"
    />
  );
};

export default TransactionsModal;
