import { Modal, Button, Group, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import { mutate } from "swr";
import * as Yup from "yup";
import api from "../../../../api";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";
interface Props {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  user?: any;
  setUser: (user: any) => void;
}

const UsersModal = (props: Props) => {
  const { opened, setOpened, user, setUser } = props;

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validate: yupResolver(
      user ? validationSchemaUpdate : validationSchemaCreate
    ),
  });

  useEffect(() => {
    if (user) {
      form.setValues(user);
    }
  }, [opened, user]);

  useEffect(() => {
    if (!opened) {
      form.reset();
      setUser(null);
    }
  }, [opened]);

  const update = () => {
    api
      .put(`/users/${user.id}`, {
        ...form.values,
        password: form.values.password ? form.values.password : null,
        password_confirmation: form.values.password_confirmation
          ? form.values.password_confirmation
          : null,
      })
      .then((data) => {
        mutate("/users");
        setOpened(false);
        showSuccessToast({ message: "Usuario actualizado" });
      })
      .catch((err) => {
        showErrorToast({ message: "Error al actualizar usuario" });
      });
  };

  const create = () => {
    api
      .post("/users", form.values)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Usuario creado correctamente",
          });
          mutate("/users");
          setOpened(false);
        } else {
          showErrorToast({
            message: "Error al crear el usuario",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al crear el usuario",
        });
      });
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={user ? "Editar usuario" : "Crear usuario"}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            if (user) {
              update();
            } else {
              create();
            }
          })}
        >
          <TextInput
            label="Nombre"
            placeholder="Nombre"
            required
            {...form.getInputProps("name")}
          />

          <TextInput
            label="Email"
            placeholder="Email"
            required
            {...form.getInputProps("email")}
            mt={"sm"}
            type="email"
          />

          <TextInput
            label="Contraseña"
            placeholder="Contraseña"
            required={user ? false : true}
            {...form.getInputProps("password")}
            mt={"sm"}
            type="password"
          />

          <TextInput
            label="Confirmar contraseña"
            placeholder="Confirmar contraseña"
            required={user ? false : true}
            {...form.getInputProps("password_confirmation")}
            mt={"sm"}
            type="password"
          />

          <Button type="submit" fullWidth mt={"sm"}>
            {user ? "Editar" : "Crear"} usuario
          </Button>
        </form>
      </Modal>
    </>
  );
};

const validationSchemaCreate = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  email: Yup.string().required("El email es requerido").email("Email inválido"),
  password: Yup.string().required("La contraseña es requerida"),
  password_confirmation: Yup.string()
    .required("La confirmación de la contraseña es requerida")
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
});

const validationSchemaUpdate = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  email: Yup.string().required("El email es requerido").email("Email inválido"),
  password: Yup.string().optional(),
  password_confirmation: Yup.string()
    .optional()
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
});
export default UsersModal;
