import { TableCard } from "@3t/utils";
import { Badge, Box, Button } from "@mantine/core";
import { IconFilter, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import useSWR from "swr";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import TransactionsFilterDrawer from "./components/TransactionsFilterDrawer";
import TransactionsModal from "./components/TransactionsModal";
import transactionSearch from "./utils/TransactionSearch";
import dayjs from "dayjs";
import StatsGrid from "../../components/StatsGrid";
const PreTransactions = () => {
  return (
    <MainLayout>
      <SWRrender>
        <Transactions />
      </SWRrender>
    </MainLayout>
  );
};

const Transactions = () => {
  const {
    data: { orders },
  } = useSWR("/orders", { suspense: true });

  const columns = [
    {
      Header: "Cliente",
      accessor: "client.name",
    },
    {
      Header: "Nº Transacción",
      accessor: "id",
    },
    {
      Header: "Nº Factura",
      accessor: "invoice_id",
    },
    {
      Header: "Fecha",
      accessor: "invoice_date",
      Cell: ({ value }: any) => {
        return <>{dayjs(value).format("DD/MM/YYYY")}</>;
      },
    },

    {
      Header: "Importe",
      accessor: "ammount",
    },
    {
      Header: "Pagado",
      accessor: "paid_at",
      Cell: ({ value }: any) => (
        <>
          {value ? (
            <Badge color={"green"}>Sí</Badge>
          ) : (
            <Badge color={"red"}>No</Badge>
          )}
        </>
      ),
    },
  ];

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [filteredData, setFilteredData] = useState(orders);
  const [filters, setFilters] = useState(null);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    if (filters) {
      transactionSearch(filters, orders, setFilteredData);
    } else {
      setFilteredData(orders);
    }
  }, [filters, orders]);

  const sumTotal = () => {
    let sum = filteredData.reduce((acc: any, curr: any) => {
      return acc + parseFloat(curr.ammount);
    }, 0);

    return sum + " €";
  };

  const sumPending = () => {
    let sum = filteredData.reduce((acc: any, curr: any) => {
      return !curr.paid_at ? acc + parseFloat(curr.ammount) : acc;
    }, 0);

    return sum + " €";
  };

  return (
    <>
      <TableCard
        title="Listado de operaciones"
        searchPlaceholder="Buscar operación"
        header={
          <>
            <Button
              variant="outline"
              size="sm"
              mt="sm"
              onClick={() => setShowFilterDrawer(true)}
            >
              <IconFilter />
            </Button>
            <Box mt="sm">
              <RenderFiltersHeaders filters={filters} />
            </Box>
          </>
        }
        preserveHeader
        columns={columns}
        data={filteredData}
        addButton={<IconPlus />}
        onAddButtonClick={() => setShowModal(true)}
        onRowClick={(row) => {
          setTransaction(row);
          setShowModal(true);
        }}
      />

      <StatsGrid
        cols={2}
        data={[
          {
            title: "Total operaciones",
            icon: "zoomMoney",
            value: sumTotal(),
          },
          {
            title: "Importe pendiente",
            icon: "coin",
            value: sumPending(),
          },
        ]}
      />

      <TransactionsFilterDrawer
        opened={showFilterDrawer}
        setOpened={setShowFilterDrawer}
        filters={filters}
        setFilters={setFilters}
      />
      <TransactionsModal
        opened={showModal}
        setOpened={setShowModal}
        transaction={transaction}
        setTransaction={setTransaction}
      />
    </>
  );
};

const RenderFiltersHeaders = ({ filters }: any) => {
  if (filters) {
    return (
      <>
        {filters.start_date && (
          <Badge color="blue" variant="outline">
            Fecha desde: {filters.start_date.toLocaleDateString()}
          </Badge>
        )}
        {filters.end_date && (
          <Badge color="blue" variant="outline">
            Fecha hasta: {filters.end_date.toLocaleDateString()}
          </Badge>
        )}
        {filters.customers.length > 0 && (
          <Badge color="blue" variant="outline">
            Cliente: {filters.customers.join(", ")}
          </Badge>
        )}
        {filters.paid && (
          <Badge color="blue" variant="outline">
            Pagado: {filters.paid}
          </Badge>
        )}
        {filters.seller && (
          <Badge color="blue" variant="outline">
            Vendedor: {filters.seller}
          </Badge>
        )}
      </>
    );
  }
  return <></>;
};

export default PreTransactions;
