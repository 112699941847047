import useSWR from "swr";
import StatsGrid from "../../components/StatsGrid";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";

const PreDashboard = () => {
  return (
    <MainLayout>
      <SWRrender>
        <Dashboard />
      </SWRrender>
    </MainLayout>
  );
};

const Dashboard = () => {
  const {
    data: { stats },
  } = useSWR("/stats", { suspense: true });
  return (
    <>
      <StatsGrid
        data={[
          {
            title: "Clientes",
            icon: "user",
            value: stats.total_clients,
          },
          {
            title: "Operaciones",
            icon: "arrows",
            value: stats.total_orders,
          },
          {
            title: "Operaciones pendientes",
            icon: "receipt",
            value: stats.total_unpaid_orders,
          },
          {
            title: "Total pagado",
            icon: "coin",
            value: String(stats.total_paid_ammount) + " €",
          },
          {
            title: "Total pendiente",
            icon: "zoomMoney",
            value: String(stats.total_unpaid_ammount) + " €",
          },
        ]}
      />
    </>
  );
};

export default PreDashboard;
