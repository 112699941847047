import {
  Navbar,
  ScrollArea,
  createStyles,
  AppShell,
  Header,
  Box,
} from "@mantine/core";
import {
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconMenu2,
  IconArrowsRightLeft,
  IconUsers,
  IconKey,
} from "@tabler/icons";
import { useState } from "react";
import LinksGroup from "../../components/NavbarLinksGroup/NavbarLinksGroup";

const mockdata = [
  { label: "Dashboard", icon: IconGauge, route: "/" },
  { label: "Usuarios", icon: IconKey, route: "/users" },
  { label: "Clientes", icon: IconUsers, route: "/customers" },
  { label: "Operaciones", icon: IconArrowsRightLeft, route: "/transactions" },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    //paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  openMenu: {
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      display: "none",
    },
  },
}));

export default function MainLayout(props: any) {
  const { classes } = useStyles();
  const [visible, setVisible] = useState(true);

  const links = mockdata.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  return (
    <AppShell
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      })}
      navbar={
        <Navbar
          width={{ sm: 300 }}
          p="md"
          hiddenBreakpoint={"sm"}
          hidden={visible}
          className={classes.navbar}
        >
          <Navbar.Section grow className={classes.links} component={ScrollArea}>
            <div className={classes.linksInner}>{links}</div>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header
          height={50}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => setVisible(!visible)}
            style={{ marginLeft: "10px" }}
            className={classes.openMenu}
          >
            <IconMenu2 size={16} />
          </Box>
        </Header>
      }
    >
      {props.children}
    </AppShell>
  );
}
