export function getAccessToken() {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("token");
    return token;
  }
}
export function saveToken(token: string) {
  localStorage.setItem("token", token);
}

export function deleteToken() {
  localStorage.removeItem("token");
}
