import React, { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../utils/toasts";
import Loading from "../Loading";
import LoadingCompany from "../LoadingCompany";
import SWRError from "../SWRError";

interface SWRrenderProps {
  loadingHeight?: number;
  redirectOnError?: string;
  children: React.ReactNode;
  toastMessage?: string;
  showLoading?: boolean;
  spinner?: boolean;
}

const SWRrender: React.FC<SWRrenderProps> = (props: SWRrenderProps) => {
  const navigate = useNavigate();
  const {
    loadingHeight = 250,
    redirectOnError,
    children,
    toastMessage,
    showLoading = true,
    spinner = false,
  } = props;

  const LoadingComponent = () => {
    if (spinner) {
      return <LoadingCompany />;
    } else {
      return <Loading height={loadingHeight} />;
    }
  };
  return (
    <ErrorBoundary
      redirectOnError={redirectOnError}
      navigate={navigate}
      toastMessage={toastMessage}
    >
      <Suspense fallback={showLoading ? <LoadingComponent /> : <></>}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default SWRrender;

class ErrorBoundary extends React.Component<any, any> {
  state: any = { error: null };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    if (this.state.error) {
      if (this.props.redirectOnError) {
        if (this.props.toastMessage) {
          showErrorToast({ message: this.props.toastMessage });
        }
        this.props.navigate(this.props.redirectOnError);
      } else {
        return <SWRError />;
      }
    }
    return this.props.children;
    // handle other errors or return some error UI
  }
}
