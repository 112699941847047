import { TableCard } from "@3t/utils";
import { Badge, Box, Button, Card, Grid, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import api from "../../../api";
import SWRrender from "../../../components/SWRrender";
import MainLayout from "../../../layouts/MainLayout";
import { showErrorToast, showSuccessToast } from "../../../utils/toasts";
import dayjs from "dayjs";
import { openConfirmModal } from "@mantine/modals";
import StatsGrid from "../../../components/StatsGrid";
import TransactionsModal from "../../Transactions/components/TransactionsModal";
const PreUpdate = () => {
  const { id } = useParams();
  return (
    <MainLayout>
      <SWRrender>
        <UpdateCustomer id={id} />
      </SWRrender>
    </MainLayout>
  );
};

const columns = [
  {
    Header: "Nº Transacción",
    accessor: "id",
  },
  {
    Header: "Nº Factura",
    accessor: "invoice_id",
  },
  {
    Header: "Fecha",
    accessor: "invoice_date",
    Cell: ({ value }: any) => {
      return <>{dayjs(value).format("DD/MM/YYYY")}</>;
    },
  },

  {
    Header: "Importe",
    accessor: "ammount",
  },
  {
    Header: "Pagado",
    accessor: "paid_at",
    Cell: ({ value }: any) => (
      <>
        {value ? (
          <Badge color={"green"}>Sí</Badge>
        ) : (
          <Badge color={"red"}>No</Badge>
        )}
      </>
    ),
  },
];

const UpdateCustomer = (props: any) => {
  const { id } = props;

  const {
    data: { client },
  } = useSWR(`/clients/${id}`, { suspense: true });

  const form = useForm({
    initialValues: {
      name: "",
      seller: "",
    },
  });

  useEffect(() => {
    if (client) {
      form.setValues(client);
    }
  }, [client]);

  const navigate = useNavigate();

  const openDeleteModal = () =>
    openConfirmModal({
      title: "Eliminar cliente",
      children: (
        <Text size="sm">
          ¿Estás seguro de que quieres eliminar este cliente? Esta acción no se
          puede deshacer.
          <Text weight={"bold"}>
            No podrás eliminar un cliente que tiene operaciones asignadas
          </Text>
        </Text>
      ),
      labels: { confirm: "Eliminar", cancel: "Cancelar" },
      onCancel: () => console.log("Cancelar"),
      onConfirm: () => {
        api
          .delete(`/clients/${id}`)
          .then((res) => {
            if (res.data.ok) {
              showSuccessToast({
                message: "Cliente eliminado correctamente",
              });
              navigate("/customers");
            } else {
              showErrorToast({
                message: "Error al eliminar el cliente",
              });
            }
          })
          .catch((err) => {
            if (err.response.data?.errors.id) {
              showErrorToast({
                message:
                  "No se puede eliminar un cliente que tiene operaciones asignadas",
              });
            } else {
              showErrorToast({
                message: "Error al eliminar el cliente",
              });
            }
          });
      },
    });

  const sumTotal = () => {
    let sum = client.orders?.reduce((acc: any, curr: any) => {
      return acc + parseFloat(curr.ammount);
    }, 0);

    return sum + " €";
  };

  const sumPending = () => {
    let sum = client.orders?.reduce((acc: any, curr: any) => {
      return !curr.paid_at ? acc + parseFloat(curr.ammount) : acc;
    }, 0);

    return sum + " €";
  };

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [order, setOrder] = useState(null);
  return (
    <>
      <Card>
        <Card.Section p="md">
          <Text size={"md"}>Actualizar cliente</Text>
        </Card.Section>

        <Card.Section p="md">
          <form
            onSubmit={form.onSubmit((values) => {
              api
                .put(`/clients/${id}`, values)
                .then((res) => {
                  if (res.data.ok) {
                    showSuccessToast({
                      message: "Cliente actualizado correctamente",
                    });

                    navigate(`/customers`);
                  } else {
                    showErrorToast({
                      message: "Error al actualizar el cliente",
                    });
                  }
                })
                .catch((err) => {
                  showErrorToast({
                    message: "Error al actualizar el cliente",
                  });
                });
            })}
          >
            <TextInput
              name="name"
              label="Nombre del cliente"
              mt="sm"
              {...form.getInputProps("name")}
            />
            <TextInput
              name="seller"
              label="Número de comercial"
              mt="sm"
              {...form.getInputProps("seller")}
            />
            <Grid>
              <Grid.Col span={6}>
                <Button
                  color="red"
                  fullWidth
                  mt={"sm"}
                  onClick={openDeleteModal}
                >
                  Eliminar
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button fullWidth mt="sm" type="submit">
                  Actualizar cliente
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Card.Section>
      </Card>

      <StatsGrid
        cols={2}
        data={[
          {
            title: "Total operaciones",
            icon: "zoomMoney",
            value: sumTotal(),
          },
          {
            title: "Importe pendiente",
            icon: "coin",
            value: sumPending(),
          },
        ]}
      />

      <Box mt="md">
        <TableCard
          columns={columns}
          data={client?.orders}
          enableSearch={false}
          title="Operaciones del cliente"
          onRowClick={(row) => {
            setOrder(row);
            setShowOrderModal(true);
          }}
          addButton={<></>}
        />
      </Box>

      <TransactionsModal
        opened={showOrderModal}
        setOpened={setShowOrderModal}
        transaction={order}
        setTransaction={setOrder}
      />
    </>
  );
};

export default PreUpdate;
