import { Card, createStyles, Text, useMantineTheme } from "@mantine/core";
import { CircleX } from "tabler-icons-react";

const SWRError = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  return (
    <Card p="xl" mt={"sm"}>
      <div className={classes.container}>
        <CircleX color={theme.colors.red[7]} size={80} />{" "}
        <Text weight={"bold"}>Se ha producido un error</Text>
      </div>
    </Card>
  );
};

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default SWRError;
