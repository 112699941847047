import dayjs from "dayjs";

const search = (filters: any, data: any, setFilteredData: any) => {
  let response: any = [];
  let used_response = false;
  if (filters.start_date && filters.end_date) {
    console.log("1");
    response = data.filter((item: any) => {
      const date = new Date(item.invoice_date);
      return date >= filters.start_date && date <= filters.end_date;
    });
    used_response = true;
  }

  if (filters.start_date && !filters.end_date) {
    console.log("2");
    response = data.filter((item: any) => {
      const date = new Date(item.invoice_date);
      return date >= filters.start_date;
    });
    used_response = true;
  }

  if (!filters.start_date && filters.end_date) {
    console.log("3");
    response = data.filter((item: any) => {
      const date = new Date(dayjs(item.invoice_date).format("YYYY-MM-DD"));
      console.log(date);
      return date <= filters.end_date;
    });
    used_response = true;
  }

  if (
    filters.customers.length > 0 &&
    !filters.start_date &&
    !filters.end_date
  ) {
    console.log("4");
    response = data.filter((item: any) => {
      return filters.customers.includes(item.client_id);
    });
    used_response = true;
  }

  if (
    filters.customers.length > 0 &&
    (filters.start_date || filters.end_date)
  ) {
    console.log("5");
    response = response.filter((item: any) => {
      return filters.customers.includes(item.client_id);
    });
    used_response = true;
  }

  if (filters.seller && used_response) {
    response = response.filter((item: any) => {
      return String(item.client.seller) === filters.seller;
    });
    used_response = true;
  }

  if (filters.seller && !used_response) {
    response = data.filter((item: any) => {
      return String(item.client.seller) === filters.seller;
    });
    used_response = true;
  }

  if (
    filters.paid &&
    !filters.start_date &&
    !filters.end_date &&
    filters.customers.length === 0
  ) {
    console.log("6");
    console.log(Boolean());
    response = data.filter((item: any) => {
      return String(Boolean(item.paid_at)) == filters.paid;
    });
    used_response = true;
  }

  if (filters.paid && (filters.start_date || filters.end_date)) {
    response = response.filter((item: any) => {
      return String(Boolean(item.paid_at)) == filters.paid;
    });
    used_response = true;
  }

  if (
    filters.paid &&
    !filters.start_date &&
    !filters.end_date &&
    filters.customers.length > 0
  ) {
    console.log("7");
    response = response.filter((item: any) => {
      return String(Boolean(item.paid_at)) == filters.paid;
    });
    used_response = true;
  }

  if (
    !filters.paid &&
    !filters.start_date &&
    !filters.end_date &&
    filters.customers.length === 0 &&
    !filters.seller
  ) {
    response = data;
  }
  setFilteredData(response);
};

export default search;
