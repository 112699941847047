import { useState } from "react";
import { Modal, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import api from "../../../../api";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";
import { mutate } from "swr";

interface CustomersModalProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
}
const CustomersModal = (props: CustomersModalProps) => {
  const { opened, setOpened } = props;

  const form = useForm({
    initialValues: {
      name: "",
      seller: "",
    },
  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Crear cliente"
      >
        <form
          onSubmit={form.onSubmit((values) => {
            api
              .post("/clients", values)
              .then((res) => {
                if (res.data.ok) {
                  showSuccessToast({
                    message: "Cliente creado correctamente",
                  });
                  mutate("/clients");
                  setOpened(false);
                } else {
                  showErrorToast({
                    message: "Error al crear el cliente",
                  });
                }
              })
              .catch((err) => {
                showErrorToast({
                  message: "Error al crear el cliente",
                });
              });
          })}
        >
          <TextInput
            name="name"
            placeholder="Nombre del cliente"
            label="Nombre del cliente"
            required
            {...form.getInputProps("name")}
          />

          <TextInput
            name="seller"
            placeholder="Número de comercial"
            mt="sm"
            label="Número de comercial"
            {...form.getInputProps("seller")}
          />
          <Button fullWidth type="submit" mt="sm">
            Crear cliente
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default CustomersModal;
