import * as React from "react";
import { Routes, Route, Link, Outlet, Navigate } from "react-router-dom";
import Customers from "./pages/Customers";
import UpdateCustomer from "./pages/Customers/Update/Update";
import Dashboard from "./pages/Dashboard";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import LoginPage from "./pages/Login/Login";
import useUser from "./hooks/useUser";

const ProtectedRoute = ({ user, redirectPath = "/auth/login" }: any) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function Router() {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <></>;
  }
  return (
    <div className="App">
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/" element={<Dashboard />} />

          <Route path="/users" element={<Users />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:id" element={<UpdateCustomer />} />
          <Route path="/transactions" element={<Transactions />} />
        </Route>
      </Routes>
    </div>
  );
}

export default Router;
