import { Center, Loader, Text } from "@mantine/core";

const LoadingCompany = () => {
  return (
    <>
      <Center
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Loader size={"xl"} />
      </Center>
    </>
  );
};

export default LoadingCompany;
