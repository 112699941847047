import { showNotification } from "@mantine/notifications";
import { Check, X } from "tabler-icons-react";

export const showErrorToast = (args) => {
  showNotification({
    ...args,
    color: "red",
    icon: <X />,
  });
};

export const showSuccessToast = (args) => {
  showNotification({
    ...args,
    color: "green",
    icon: <Check />,
  });
};
