import { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Group,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import useSWR from "swr";
import SWRrender from "../../../../components/SWRrender";

interface TransactionsFilterDrawerProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  setFilters: (value: any) => void;
  filters: any;
}

const TransactionsFilterDrawer = (props: TransactionsFilterDrawerProps) => {
  const { opened, setOpened, filters, setFilters } = props;

  useEffect(() => {
    if (opened && filters) {
      form.setValues(filters);
    }
  }, [opened]);

  const form = useForm({
    initialValues: {
      customers: [],
      paid: false,
      start_date: "",
      end_date: "",
      seller: "",
    },
  });
  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Filtrar operaciones"
        padding="xl"
        size="md"
        position="right"
      >
        <form
          onSubmit={form.onSubmit((values) => {
            setFilters(values);
            //setOpened(false);
          })}
        >
          <SWRrender>
            <RenderCustomerSelect form={form} />{" "}
          </SWRrender>
          <Select
            name="paid"
            label="Estado"
            placeholder="Estado"
            {...form.getInputProps("paid")}
            mt={"sm"}
            data={[
              { label: "Pagado", value: "true" },
              { label: "No pagado", value: "false" },
            ]}
          />
          <TextInput
            name="seller"
            placeholder="Número de comercial"
            label="Número de comercial"
            mt={"sm"}
            {...form.getInputProps("seller")}
          />
          <DatePicker
            label="Fecha de inicio"
            allowFreeInput
            inputFormat="DD/MM/YYYY"
            mt="sm"
            {...form.getInputProps("start_date")}
          />{" "}
          <DatePicker
            label="Fecha de fin"
            allowFreeInput
            inputFormat="DD/MM/YYYY"
            mt="sm"
            {...form.getInputProps("end_date")}
          />{" "}
          <Button fullWidth type="submit" mt="sm">
            Aplicar filtros
          </Button>
        </form>
      </Drawer>
    </>
  );
};

const RenderCustomerSelect = (props: any) => {
  const { form } = props;
  const {
    data: { clients },
  } = useSWR("/clients", { suspense: true });

  const customerOptions = clients.map((customer: any) => ({
    label: customer.name,
    value: customer.id,
  }));

  return (
    <MultiSelect
      name="customers"
      label="Clientes"
      placeholder="Clientes"
      {...form.getInputProps("customers")}
      data={customerOptions}
    />
  );
};
export default TransactionsFilterDrawer;
