import { Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useState } from "react";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import UsersTable from "./components/UsersTable";
import { TableCard } from "@3t/utils";
import UsersModal from "./components/UsersModal";
import useSWR from "swr";

const PreUsers = () => {
  return (
    <MainLayout>
      <SWRrender>
        <Users />
      </SWRrender>
    </MainLayout>
  );
};

const columns = [
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
];
const Users = () => {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const {
    data: { users },
  } = useSWR("/users", { suspense: true });

  return (
    <>
      <TableCard
        columns={columns}
        data={users}
        title="Listado de usuarios"
        onAddButtonClick={() => setShowModal(true)}
        enableSearch={false}
        addButton={<IconPlus />}
        onRowClick={(row) => {
          setUser(row);
          setShowModal(true);
        }}
      />
      <UsersModal
        opened={showModal}
        setOpened={setShowModal}
        user={user}
        setUser={setUser}
      />
    </>
  );
};

export default PreUsers;
